<template>
  <div class="seefactory">
    <!-- 第一部分信息 -->
    <div class="header_info">
      <div class="bsicinfo">
        <div class="left"></div>
        <div class="baici">基础信息</div>
      </div>
      <div class="info">
        <img :src="picture" alt="" class="cover" />
        <div class="basical">
          <div class="title">{{ fact_name }}</div>
          <div class="dic">
            <span>联系人：</span>
            <span>{{ name }}</span>
          </div>
          <div class="dic">
            <span>上架状态：</span>
            <span> {{ status == 1 ? '已上架' : '未上架' }}</span>
          </div>
        </div>
        <div class="basical">
          <div class="title"></div>
          <div class="dic">
            <span>联系方式：</span>
            <span>{{ phone }}</span>
            <span class="open">营业执照：</span>
            <span class="opendes" @click="handleclick('port')">点击查看</span>
          </div>
          <div class="dic">
            <span>工厂地址：</span>
            <span>{{ address }}</span>
          </div>
          <div div class="dic">
            <span>是否是云工厂认证：</span
            ><span>{{ certification ? '是' : '否' }}</span>
          </div>
          <div class="dic">
            <span> 对应抖音账号：</span>
            <span>号1:{{ tiktok1 }}</span>
            <span v-if="tiktok2">号2:{{ tiktok2 }}</span>
          </div>
          <div div class="dic">
            <span>公司税号：{{ tax_code }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <div class="edit" @click="change">编辑</div>
        <div class="up" @click="uppliu">
          {{ status == 1 ? '已上架' : '上架' }}
        </div>
      </div> -->
    </div>
    <el-dialog :visible.sync="dialogTableVisible" width="30%">
      <img :src="diaImgUrl" alt="" class="Preview" />
    </el-dialog>
    <!-- 第二部分信息 -->
    <div class="contentcenter">
      <div class="bsicinfo">
        <div class="left"></div>
        <div class="baici">生产信息</div>
      </div>
      <div class="equipment">设备信息</div>
      <div class="equinfo">六面钻、五面钻</div>
      <div class="equipment">其他信息</div>
      <div class="otherinfo">
        <div class="factorinso">
          <span>厂房面积(㎡)：</span>
          <span>{{ fact_area }}</span>
        </div>
        <div class="factorinso">
          <span>员工人数：</span>
          <span>{{ population }}</span>
        </div>
        <div class="factorinso">
          <span>每月生产量(㎡)：</span>
          <span>{{ output }}</span>
        </div>
      </div>
    </div>
    <!-- 第三部分信息 -->
    <div class="foter">
      <div class="bsicinfo">
        <div class="left"></div>
        <div class="baici">工厂简介</div>
      </div>
      <div class="emit" v-if="info.length == 0">
        <img src="@/assets/emit.png" alt="" class="emitimg" />
        <div class="emitdes">暂无详细信息</div>
      </div>
      <div id="editor-content-textarea" v-html="info" class="eiderText"></div>
    </div>
    <!-- 经销商报价表 -->
    <div class="price_sale">
      <div class="bsicinfo">
        <div class="left"></div>
        <div class="baici">经销商报价表：</div>
      </div>
      <div class="price_excel_img">
        <div v-if="isPicTable == 1" class="price_img">
          <img :src="quotation_table" alt="" />
          <span @click="handleclick('price')">预览</span>
        </div>
        <div v-if="isPicTable == 2" class="price_excel">
          <div>经销商报价表.excel</div>
          <span @click="downloadPrice">下载</span>
        </div>
        <div v-if="isPicTable == 0">暂经销商报价表</div>
      </div>
    </div>
    <!-- 案例照片 -->
    <div class="case_pic">
      <div class="bsicinfo">
        <div class="left"></div>
        <div class="baici">案例照片：</div>
      </div>
      <div class="case_img_out">
        <div
          class="case_img"
          v-for="(item, index) in info_picture"
          :key="index"
        >
          <img :src="item" alt="" />
        </div>
      </div>
      <div style="padding-left: 20px" v-if="!info_picture.length">
        暂无案例图片
      </div>
    </div>
    <div class="back" @click="goback"><span>返回</span></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPicTable: 0, //判断为图片还是xlsx
      diaImgUrl: '',
      fact_name: '', //工厂名
      name: '', //联系人
      status: '', //上架状态
      phone: '', //联系方式
      address: null, //工厂地址
      fact_area: null, //工厂面积
      population: null, //员工人数
      output: null, //生产量
      info: '', //工厂简介
      license: '', //营业执照
      dialogTableVisible: false,
      id: null, //信息对应id
      picture: '', //工厂封面
      examine_status: 0, // 审核状态
      certification: 0, // 是否是云工厂
      tiktok1: '',
      tiktok2: '',
      tax_code: '',
      quotation_table: '',
      info_picture: [],
    }
  },
  created() {
    this.getListInfo(Number(this.$route.query.id))
  },
  methods: {
    // 回显
    getListInfo(id) {
      this.$request.getReviewList(
        {
          id: id,
        },
        (res) => {
          let data = res.data
          this.fact_name = data.fact_name
          this.name = data.name
          this.status = data.status
          this.phone = data.phone
          this.address =
            data.province + data.city + data.district + data.address
          this.fact_area = data.fact_area
          this.population = data.population
          this.output = data.output
          this.info = data.info
          this.license = data.license
          this.id = data.id
          this.picture = data.picture
          if (data.tiktok_id.indexOf(',')) {
            this.tiktok1 = data.tiktok_id.split(',')[0]
            this.tiktok2 = data.tiktok_id.split(',')[1]
          } else {
            this.tiktok1 = data.tiktok_id
          }
          this.tax_code = data.tax_code
          this.quotation_table = data.quotation_table
          if (this.quotation_table) {
            if (this.getFileType(this.quotation_table) == 'image') {
              this.isPicTable = 1
            }
            if (this.getFileType(this.quotation_table) == 'excel') {
              this.isPicTable = 2
            }
          } else {
            this.isPicTable = 0
          }
          this.info_picture = data.info_picture
          this.certification = data.certification
        }
      )
    },
    // 文件类型
    getFileType(fileName) {
      let suffix = ''
      let result = ''
      if (fileName) {
        const flieArr = fileName.split('.')
        suffix = flieArr[flieArr.length - 1]
      }
      if (!suffix) return false
      suffix = suffix.toLocaleLowerCase()
      // 匹配图片
      const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      result = imgList.find((item) => item === suffix)
      if (result) return 'image'
      // 匹配excel
      const excelList = ['xls', 'xlsx']
      result = excelList.find((item) => item === suffix)
      if (result) return 'excel'
      // 其他文件类型
      return 'other'
    },
    // 查看大图(预览)
    handleclick(type) {
      switch (type) {
        case 'port':
          this.diaImgUrl = this.license
          break
        case 'price':
          this.diaImgUrl = this.quotation_table
          break
      }
      this.dialogTableVisible = true
    },
    goback() {
      window.history.go(-1)
    },
    // 下载经销商报价表
    downloadPrice() {
      window.location.href = this.quotation_table
    },
  },
}
</script>
<style scoped lang="less">
.factory_infos {
  background: rgba(243, 243, 243, 1);
}
.header_info {
  padding: 12px;
  width: 100%;
  height: 240px;
  background: white;
}
.bsicinfo {
  display: flex;
  // background: rgba(220, 220, 220, 1);
  height: 40px;
  padding: 8px 0;
  // padding-left: 16px;
  .left {
    width: 4px;
    height: 24px;
    background: rgba(100, 118, 255, 1);
    margin-right: 8px;
  }
  .baici {
    color: rgba(0, 0, 0, 0.9);
    font-size: 16px;
    font-weight: 400;
  }
}
.info {
  display: flex;
  height: 102px;
  margin-top: 8px;
  .cover {
    width: 102px;
    height: 102px;
  }
  .basical {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 126px;
  }
  .title {
    color: rgba(0, 0, 0, 0.9);
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    height: 28px;
  }
  .dic {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    .open {
      margin-left: 156px;
    }
    .opendes {
      cursor: pointer;
      color: rgba(100, 118, 255, 1);
    }
  }
}
.contentcenter {
  background: white;
  padding: 12px;
  margin-top: 16px;
  height: 314px;
  .equipment {
    padding-left: 24px;
    background: rgba(243, 243, 243, 1);
    height: 42px;
    line-height: 42px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  .equinfo {
    border: 1px solid rgba(128, 128, 128, 0.103);
    padding-left: 24px;
    height: 68px;
    line-height: 68px;
    margin-bottom: 16px;
  }
  .otherinfo {
    display: flex;
    justify-content: flex-start;
  }
  .factorinso {
    border: 1px solid rgba(128, 128, 128, 0.123);
    height: 68px;
    line-height: 68px;
    flex: 1;
    padding-left: 24px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
}
.price_sale {
  background: white;
  padding: 12px;
  margin-top: 16px;
  .bsicinfo {
    display: flex;
    // background: rgba(220, 220, 220, 1);
    height: 40px;
    padding: 8px 0;
    // padding-left: 16px;
    .left {
      width: 4px;
      height: 24px;
      background: rgba(100, 118, 255, 1);
      margin-right: 8px;
    }
    .baici {
      color: rgba(0, 0, 0, 0.9);
      font-size: 16px;
      font-weight: 400;
    }
  }
  .price_excel_img {
    margin-left: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .price_img {
      width: 150px;
      height: 180px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-start;
      > img {
        width: 100%;
        height: 100%;
      }
      > span {
        color: #6476ff;
        cursor: pointer;
      }
    }
    .price_excel {
      margin-left: 20px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      span {
        color: #6476ff;
        cursor: pointer;
      }
    }
  }
}
.case_pic {
  background: white;
  padding: 12px;
  margin-top: 16px;
  .bsicinfo {
    display: flex;
    // background: rgba(220, 220, 220, 1);
    height: 40px;
    padding: 8px 0;
    // padding-left: 16px;
    .left {
      width: 4px;
      height: 24px;
      background: rgba(100, 118, 255, 1);
      margin-right: 8px;
    }
    .baici {
      color: rgba(0, 0, 0, 0.9);
      font-size: 16px;
      font-weight: 400;
    }
  }
  .case_img_out {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .case_img {
      margin-left: 20px;
      width: 150px;
      height: 180px;
      margin-right: 10px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.back {
  background: white;
  padding: 12px;
  margin-top: 16px;
  text-align: center;
  > span {
    padding: 10px 15px;
    border-radius: 3px;
    background: rgb(24, 144, 255);
    color: white;
    cursor: pointer;
  }
}
.foter {
  background: white;
  padding: 12px;
  margin-top: 16px;
}
.btn {
  display: flex;
  padding-left: 120px;
  margin-top: 24px;
  div {
    width: 60px;
    height: 32px;
    background: #6476ff;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    margin-right: 8px;
    text-align: center;
    line-height: 32px;
  }
  .up {
    width: 60px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 1px solid #dcdcdc;
    background: #fff;
  }
}
.emitimg {
  width: 59px;
  height: 61px;
}
.emit {
  text-align: center;
  .emitdes {
    font-size: 12px;

    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
  }
}
.Preview {
  width: 100%;
  height: 100%;
}
</style>
